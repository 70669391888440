<template>
  <list-template
      :total="total"
      :table-data="tableData"
      :table-config="tableConfig"
      has-back
      @onChangePage="changeCurrentPage"
  ></list-template>
</template>

<script>
export default {
	_config:{"route":{"path":"history","meta":{"title":"历史记录","style":1}}},
  data(){
    return {
      // 表格配置
      tableConfig: [
        {
          type:"index",
          label:"序号",
          width:"60rem",
          property: {
            index: (index) => {
              return (this.page - 1) * 20 + index + 1;
            }
          }
        },
        {
          prop:"begin_date",
          label:"开始时间"
        },
        {
          prop:"end_date",
          label:"结束时间"
        },
        {
          prop:"class_name",
          label:"班级"
        },
        {
          prop:"class_type",
          label:"班级类型"
        },
        {
          prop:"teacher_type",
          label:"教师类型"
        },
        {
          prop:"subject",
          label:"教学科目"
        },
        {
          prop: "grade_name",
          label: "年级"
        },
        {
          prop: "school_name",
          label: "校区"
        }
      ],
      // 当前页码
      page: 1,
      total: 0,
      // 表格中的数据
      tableData:[],
      id:null
    }
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getData();
  },
  methods:{
    // 分页被更改
    changeCurrentPage(e) {
      this.page = e;
      this.getData();
    },
    getData(){
      this.$_axios.get("/teacher/class",{params: {id: this.id,page:this.page}}).then(res=>{
        let {data} = res.data;
        let { total } = this.$tools.getPaginationInfo(res.headers)
        this.total = total
        this.tableData = data;
      })
    }
  }
}
</script>

<style scoped>

</style>
